import React, { useState, useEffect } from 'react';
import Carousel from 'nuka-carousel';

import './styles.css';
import './NukaCarousel.css';

const NukaCarousel = ({media, team_title, type}) => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const dateOptions = {
    month: 'short',
    day: 'numeric'
  }
  // let carouselVids = el.getAttribute('params');
  // carouselVids = JSON.parse(carouselVids);

  let config = {
    containerClassName: 'ContainerVideo',
    nextButtonClassName: 'next u-layoutBtnColor',
    // nextButtonStyle: CSSProperties,
    nextButtonText: '\uf054',
    pagingDotsClassName: 'd-none',
    // pagingDotsContainerClassName: string,
    // pagingDotsStyle: CSSProperties,
    prevButtonClassName: 'prev u-layoutBtnColor',
    // prevButtonStyle: CSSProperties,
    prevButtonText: '\uf053'
  }
/*
// config for setting the amount of slides to show the user at certain breakpoints
  const setShowSlideAmt = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) setSlidesToShow(1);
    if (767 < windowWidth && windowWidth < 992) setSlidesToShow(2);
    if (991 < windowWidth && windowWidth < 1200) setSlidesToShow(3);
    if (1199 < windowWidth) setSlidesToShow(4);
  }
//*/
  useEffect(() => {
    // Set amount of slides that are shown on screen
    // setShowSlideAmt();

    // add listener to window to we can check how wide it is and change amount to show
    // window.addEventListener("resize", setShowSlideAmt);

    // remove event listener when closed
    // return () => window.removeEventListener("resize", setShowSlideAm
  }, [])
//*/
  return (
    <Carousel
      cellSpacing={10}
      slidesToShow={slidesToShow}
      defaultControlsConfig={config}
      wrapAround={false}
    >
      {
        media.map( (objInfo, idx) => {
          let date;
          if (type ==='videos') {
            date = new Date(objInfo.created_at);
          } else {
            date = new Date(objInfo.date);
          }
          date = date.toLocaleDateString('en-US', dateOptions);
          let author = `${objInfo._embedded.creator.first_name.trim()} ${objInfo._embedded.creator.last_name.trim()}`;

          return (
            <article key={idx}>
              <div className={`${type === 'videos' ? 'u-videoThumbnail' : 'u-photoThumbnail'}`}>
                { type === 'videos' ? (
                  <a href={`/videos/${objInfo.unique_name}`} className="slider-img" style={{backgroundImage: `url( ${objInfo._links.thumbnail ? objInfo._links.thumbnail.href : ''})` }}></a>
                ) : (
                  <a href={`/pictures/${objInfo.id}`} className="slider-img" style={{backgroundImage: `url( ${objInfo._links.v_large ? objInfo._links.v_large.href : ''})` }}></a>
                )}
              </div>
              <div>
                { objInfo.description && type === 'videos' ? (
                  <a href={`/videos/${objInfo.unique_name}`} className="Slide-title"><h3 className="u-layoutColorTxt Slide-title">{objInfo.description}</h3></a>
                ) : objInfo.description ? (
                  <a href={`/pictures/${objInfo.id}`} className="Slide-title"><h3 className="u-layoutColorTxt Slide-title">{objInfo.description}</h3></a>
                ) : (
                  <a className="Slide-title">
                    <h3 className="u-layoutColorTxt Slide-title">&nbsp;</h3>
                  </a>
                )}
                <div className="d-flex justify-content-between Media-info">
                  <p className="mb-0">{team_title}</p>
                  <p className="mb-0"><small>{author} &bull; {date}</small></p>
                </div>
              </div>
            </article>
          )
        })
      }
    </Carousel>
  )
}

export default NukaCarousel;
